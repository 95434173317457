<template>
    <v-app-bar
        color="#404040"
        dark
        fixed
        v-if="$vuetify.breakpoint.xsOnly"
    >
        <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
        <v-navigation-drawer 
            v-model="sidebar" 
            app
            hide-overlay 
            temporary
            width="300px"
        >
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-img class="logo" :src="require('@/assets/logo.png')"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title>Coquinaria</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item v-if="$route.params.id && $route.name === 'Recipes'">
                    <v-list-item-icon><v-icon>mdi-format-list-numbered</v-icon></v-list-item-icon>
                    <v-list-item-title><router-link to="/recipes" class="tabs active" tag="li">{{ getCategorieTitre() }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon><v-icon>mdi-format-list-numbered</v-icon></v-list-item-icon>
                    <v-list-item-title><router-link to="/recipes" class="tabs" :class="active === 'recipes' ? 'active' : ''" tag="li">{{ this.$t("recipes") }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon><v-icon>mdi-format-list-bulleted</v-icon></v-list-item-icon>
                    <v-list-item-title><router-link to="/categories" class="tabs" :class="active === 'categories' ? 'active' : ''" tag="li">{{ this.$t("categories") }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon><v-icon>mdi-plus</v-icon></v-list-item-icon>
                    <v-list-item-title><router-link to="/createRecipe" class="tabs" :class="active === 'createRecipe' ? 'active' : ''" tag="li">{{ this.$t("createRecipe") }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-group
                    prepend-icon="mdi-account"
                    :value="['profile', 'password'].includes(active)"
                >
                    <template v-slot:activator>
                        <v-list-item-title>{{ $t("account") }}</v-list-item-title>
                    </template>

                    <v-list-item>
                        <v-list-item-icon><v-icon>mdi-account-circle</v-icon></v-list-item-icon>
                        <v-list-item-title><router-link to="/account/profile" class="tabs" :class="active === 'profile' ? 'active' : ''" tag="li">{{ this.$t("account") }}</router-link></v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon><v-icon>mdi-lock</v-icon></v-list-item-icon>
                        <v-list-item-title><router-link to="/account/password" class="tabs" :class="active === 'password' ? 'active' : ''" tag="li">{{ this.$t("password") }}</router-link></v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <template v-slot:append>
                <div class="pa-2 ma-2" >
                    <v-menu
                        top
                        offset-y
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon 
                                class="ml-3 mr-6"
                                v-bind="attrs"
                                v-on="on"
                            >mdi-cog</v-icon>
                        </template>

                        <v-list>
                            <h3 class="text-center mt-2">{{ $t("preferences") }}</h3>
                            <v-switch
                                v-model="$vuetify.theme.dark"
                                @click="toggleDarkMode"
                                class="px-4 mt-4"
                                prepend-icon="mdi-white-balance-sunny"
                            >
                                <template v-slot:label>
                                    <v-icon class="mr-3">mdi-weather-night</v-icon>
                                    {{ $t("dark_theme") }}
                                </template>
                            </v-switch>
                            <v-select
                                class="mx-5 pt-3"
                                v-model="$root.$i18n.locale"
                                @change="toggleLanguage"
                                :items="$i18n.availableLocales"
                            >
                                <template slot="selection" slot-scope="data">
                                    <v-img 
                                        max-height="100%"
                                        max-width="32px" 
                                        class="mr-3"
                                        :src="require(`@/assets/icons/${data.item}.svg`)"
                                    ></v-img>
                                    {{ $t(`languages.${data.item}`) }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <v-img 
                                        max-height="100%"
                                        max-width="32px" 
                                        class="mr-3"
                                        :src="require(`@/assets/icons/${data.item}.svg`)"
                                    ></v-img>
                                    {{ $t(`languages.${data.item}`) }}
                                </template>
                            </v-select>
                        </v-list>
                    </v-menu>
                    <v-btn @click="logout"><v-icon>mdi-logout</v-icon>{{ $t("logout") }}</v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-img 
            max-height="100%"
            max-width="32px"
            :src="require('@/assets/logo.png')"
            class="mr-2"
        ></v-img>
        <v-toolbar-title>Coquinaria</v-toolbar-title>
    </v-app-bar>
    <v-app-bar
        fixed
        color="#404040"
        dark
        v-else
    >
        <v-img 
            max-height="100%"
            max-width="32px"
            :src="require('@/assets/logo.png')"
            class="mr-5"
        ></v-img>
        <v-toolbar-title>Coquinaria</v-toolbar-title>

        <router-link v-if="$route.params.id && $route.name === 'Recipes'" to="/recipes" class="tabs active" tag="li">{{ getCategorieTitre() }}</router-link>
        <router-link to="/recipes" class="tabs" :class="active === 'recipes' ? 'active' : ''" tag="li">{{ this.$t("recipes") }}</router-link>
        <router-link to="/categories" class="tabs" :class="active === 'categories' ? 'active' : ''" tag="li">{{ this.$t("categories") }}</router-link>
        <router-link to="/createRecipe" class="tabs" :class="active === 'createRecipe' ? 'active' : ''" tag="li">{{ this.$t("createRecipe") }}</router-link>

        <v-spacer></v-spacer>

        <v-menu 
            offset-y
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </template>
            <v-list>
                <h3 class="text-center mt-2">{{ $t("preferences") }}</h3>
                <v-switch
                    v-model="$vuetify.theme.dark"
                    @click="toggleDarkMode"
                    class="px-4 mt-4"
                    prepend-icon="mdi-white-balance-sunny"
                >
                    <template v-slot:label>
                        <v-icon class="mr-3">mdi-weather-night</v-icon>
                        {{ $t("dark_theme") }}
                    </template>
                </v-switch>
                <v-select
                    class="mx-5 pt-3"
                    v-model="$root.$i18n.locale"
                    @change="toggleLanguage"
                    :items="$i18n.availableLocales"
                >
                    <template slot="selection" slot-scope="data">
                        <v-img 
                            max-height="100%"
                            max-width="32px" 
                            class="mr-3"
                            :src="require(`@/assets/icons/${data.item}.svg`)"
                        ></v-img>
                        {{ $t(`languages.${data.item}`) }}
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-img 
                            max-height="100%"
                            max-width="32px" 
                            class="mr-3"
                            :src="require(`@/assets/icons/${data.item}.svg`)"
                        ></v-img>
                        {{ $t(`languages.${data.item}`) }}
                    </template>
                </v-select>
                <v-divider></v-divider>
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    link
                    @click="item.action"
                >
                    <v-list-item-title >{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<style scoped>

    .tabs{
        margin-left: 1%;
        list-style: none;
        padding: 1%;
        cursor:pointer;
        width: fit-content;
    }

    .tabs:hover, .tabs:active{
        box-shadow: 0 -4px 0 rgb(113, 160, 255) inset;
        background-color: #606060;
    }
    
    .tabs.active {
        box-shadow: 0 -4px 0 rgb(113, 160, 255) inset;
    }

    @media screen and (max-width: 600px) {
        .tabs {
            padding-bottom: 5%;
        }
    }
    
    @media screen and (min-width: 600px) and (max-width: 1439px) {
        .tabs {
            padding: 2%;
        }
    }

    @media screen and (max-width: 1900px) and (min-width: 1440px) {
        .tabs {
            padding: 1.4%;
        }
    }

    @media screen and (min-width: 2560px) {
        .tabs {
            padding: 0.8%;
        }
    }
</style>

<script>
    import { mapActions } from "vuex";

    export default {
        name: "Header",

        data() {
            return {
                sidebar: false,
                items: [
                    { title: "account", action: this.account },
                    { title: "logout", action: this.logout },
                ],
            };
        },

        props: {
            active: String,
        },

        methods: {
            ...mapActions("user", ["logout"]),

            getCategorieTitre() {
                if(this.$route.params.id) {
                    return this.$t(`recipes-${this.$route.params.id}`);
                } else {
                    return this.$t("recipes");
                }
            },

            account() {
                this.$router.push("/account/profile");
            },

            toggleDarkMode() {
                const isDarkMode = this.$vuetify.theme.isDark;
                localStorage.setItem("dark_theme", isDarkMode.toString());
            },

            toggleLanguage() {
                const language = this.$root.$i18n.locale;
                localStorage.setItem("language", language);
            },

        },
    };
</script>

<i18n>
{
    "en": {
        "languages" : {
            "en": "English",
            "fr": "French"
        },
        "recipes-1": "Recipes (First course)",
        "recipes-2": "Recipes (Dishes)",
        "recipes-3": "Recipes (Desserts)",
        "recipes-4": "Recipes (Cakes)",
        "recipes-5": "Recipes (Bakery)",
        "recipes-6": "Recipes (Dips)",
        "recipes": "All recipes",
        "categories": "Categories",
        "createRecipe": "New recipe",
        "logout": "Logout",
        "dark_theme": "Dark theme",
        "preferences": "Preferences"
    },
    "fr": {
        "languages" : {
            "en": "Anglais",
            "fr": "Français"
        },
        "recipes-1": "Recettes (Entrées)",
        "recipes-2": "Recettes (Plats)",
        "recipes-3": "Recettes (Desserts)",
        "recipes-4": "Recettes (Gâteaux)",
        "recipes-5": "Recettes (Boulangerie)",
        "recipes-6": "Recettes (Sauces)",
        "recipes": "Toutes les recettes",
        "categories": "Catégories",
        "createRecipe": "Créer une nouvelle recette",
        "logout": "Se déconnecter",
        "dark_theme": "Mode sombre",
        "preferences": "Préférences"
    }
}
</i18n>