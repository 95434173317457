<template>
    <div class="content">
        <Header/>
        <h3>
            Conditions Générales d'Utilisation
        </h3>
        <p>
            Ce site est édité par les étudiants Pierre HUGUES et Roxanne THOMAS. <br />
            Responsable de la rédaction : Roxanne THOMAS <br />
            Développement, hébergement : Pierre HUGUES<br />
            Coquinaria - https://coquinaria.pierre-hugues.fr/ <br />
        </p>
    </div>
</template>

<script>
    import Header from "@/components/login/Header";

    export default {
        name: "CGU",

        components: {
            Header,
        },
    };
</script>

<style scoped>
    p, h3 {
        margin: 1%;
    }
</style>