import CategoriesAPI from "@/api/CategoriesAPI.js";

// initial state
const state = () => ({
    categories: [],
});
  
// getters
const getters = { };

// actions
const actions = {
    async getAllCategories ({ commit }) {
        let { data } = await CategoriesAPI.getCategories();
        commit("setCategories", data);
    },
};

// mutations
const mutations = {
    setCategories (state, categories) {
        state.categories = categories;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};