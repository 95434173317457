<template>
    <div>
        <Header v-if="!updateRec" active="createRecipe" />
        <Header v-else/>
        <v-container id="content">
            <h1 v-if="!updateRec" class="mb-5">{{ $t('title') }}</h1>
            <h1 v-else class="mb-5">{{ $t('updateTitle') }}</h1>
            <v-form ref="createRecipe">
                <v-text-field
                    v-model="recipe.name" 
                    v-bind:label="$t('name')" 
                    :rules="[rules.required]" 
                    @keyup.enter="submit" 
                ></v-text-field>
                <div class="d-block d-sm-flex">
                    <v-select
                        class="mr-sm-7"
                        v-model="recipe.id_category"
                        v-bind:label="$t('category')" 
                        v-bind:no-data-text="$t('no-data-available')"
                        :items="categories"
                        item-text="name"
                        item-value="id_category"
                        :rules="[rules.required]" 
                        @keyup.enter="submit" 
                    >
                        <template slot="selection" slot-scope="data">
                            {{ $t(data.item.name) }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ $t(data.item.name) }}
                        </template>
                    </v-select>
                    <v-text-field
                        class="ml-sm-7"
                        v-model="recipe.preparation_time" 
                        v-bind:label="$t('time')" 
                        :rules="[rules.required]" 
                        @keyup.enter="submit"
                        
                    >
                        <template v-slot:append>
                            {{ $t("min") }}
                        </template>
                    </v-text-field>
                </div>
                <v-text-field
                    v-model="recipe.description" 
                    v-bind:label="$t('description')" 
                    :rules="[rules.required]" 
                    @keyup.enter="submit" 
                ></v-text-field>
                <v-data-iterator
                    :items="[{ name: 'tt' }]"
                    hide-default-footer
                >
            
                    <template v-slot:default="props">
                    <v-row>
                        <v-col
                            v-for="item in props.items"
                            :key="item.name"
                        >
                        <v-card>
                            <v-card-title class="subheading font-weight-bold">
                            {{ $t("ingredients") }}
                            </v-card-title>
            
                            <v-divider></v-divider>

                            <v-list
                                dense
                                v-for="ing in recipe.ingredients"
                                v-bind:key="ing.name"
                            >
                                <v-list-item>
                                    <v-list-item-content>
                                        {{ `${ing.quantity} ${ing.name}` }}
                                    </v-list-item-content>
                                    <v-list-item-content class="text-right">
                                        <v-btn 
                                            class="delButton" 
                                            icon
                                            @click="deleteIngredient(ing.name)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-btn 
                                            class="delButton" 
                                            icon
                                            @click="modifyIngredient(ing)"
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-list>
                                <v-form ref="addIngredient" class="d-flex" v-if="addingIngredient || modifyingIngredient">
                                    <v-text-field
                                        class="newIngQty ml-5 mr-5"
                                        v-model="newIng.quantity" 
                                        v-bind:label="$t('qty')" 
                                        :rules="[rules.required]" 
                                        @keyup.enter="addIng" 
                                    ></v-text-field>
                                    <v-text-field
                                        class="newIngName mr-5 ml-5"
                                        v-model="newIng.name" 
                                        v-bind:label="$t('name')" 
                                        :rules="[rules.required]" 
                                        @keyup.enter="addIng" 
                                    ></v-text-field>
                                    <v-btn
                                        class="ml-5 mt-2 mr-5 primary addIng"
                                        @click="cancelIng"
                                    >{{ getTextBtnCancel() }}</v-btn>
                                    <v-btn
                                        class="ml-5 mt-2 mr-5 primary addIng"
                                        @click="addIng"
                                    >{{ getTextBtn() }}</v-btn>
                                </v-form>
                                <p v-if="errorIng" style="color: red" class="ml-5">{{ $t(error_code_ing) }}</p>
                                <v-list-item>
                                    <v-btn
                                        class="mb-5 primary addButton"
                                        fab
                                        small
                                        v-if="!addingIngredient"
                                        @click="addingIngredient = true"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        </v-col>
                    </v-row>
                    </template>
                </v-data-iterator>
                <v-textarea
                    class="mt-5"
                    v-model="recipe.instructions"
                    v-bind:label="$t('step')"
                    :rules="[rules.required]"
                    rows="10"
                    outlined
                ></v-textarea>
                <p v-if="error" style="color: red">{{ $t(error_code) }}</p>
            </v-form>
            <v-card-actions class="d-flex justify-center">
                <v-btn class="ma-2" color="normal" @click="cancel">{{ $t('cancel') }}</v-btn>
                <v-btn class="ma-2" color="primary" @click="submit">{{ $t('submit') }}</v-btn>
            </v-card-actions>
        </v-container>
    </div>
</template>

<script>
    import Header from "@/components/recipes/Header.vue";
    import { mapActions, mapState } from "vuex";

    export default {
        name: "CreateRecipe",
        components: {
            Header,
        },
        async created() {
            // Si on modifie la recette
            if(this.$route.params.id) {
                this.updateRec = true;

                await this.modifyRecipe(this.$route.params.id);

                // Récupération de la recette
                Object.assign(this.recipe, this.modified);
                this.recipe.ingredients = this.modified.ingredients.slice();
            }
        },

        data() {
            return {
                addingIngredient: false,
                modifyingIngredient: false,
                error: false,
                error_code: "",
                errorIng: false,
                error_code_ing: "",
                oldIng: {},
                newIng: {
                    name: "",
                    quantity: "",
                },
                recipe: {
                    id_recipe:"",
                    name: "",
                    id_category: "",
                    preparation_time: "",
                    description: "",
                    instructions: "",
                    ingredients: [],
                },
                rules: {
                    required: v => !!v || this.$t("required"),
                },
                updateRec: false,
            };
        },

        computed: {
            ...mapState("categories", ["categories"]),
            ...mapState("recipes", ["modified"]),
        },

        methods: {
            ...mapActions("recipes", ["modifyRecipe", "createRecipe", "updateRecipe"]),

            cancel() {
                this.$refs.createRecipe.reset();
                this.$router.push("/");
            },

            async submit() {
                try {
                    if(this.$refs.createRecipe.validate()) {
                        if(this.updateRec) {
                            await this.updateRecipe(this.recipe);
                            this.$noty.success(this.$t("recipe-updated"));
                        } else {
                            await this.createRecipe(this.recipe);
                            this.$noty.success(this.$t("recipe-created"));
                        }
                        this.$router.push("/");
                    }
                } catch (error) {
                    this.error = true;
                    this.error_code = this.$t(error);
                    console.error(error);
                    this.$noty.error(this.$t("error-occured"));
                }
            },

            deleteIngredient(name) {
                this.recipe.ingredients.splice(this.recipe.ingredients.findIndex((ingredient) => ingredient.name == name), 1);
            },

            modifyIngredient(ingredient) {
                this.modifyingIngredient = true;
                Object.assign(this.oldIng, ingredient);
                Object.assign(this.newIng, ingredient);
            },

            getTextBtn() {
                let text = this.$t("submit");
                switch (this.$vuetify.breakpoint.name) {
                case "xs": text = "✓"; break;
                case "sm": text = "✓"; break;
                }
                return text;
            },

            getTextBtnCancel() {
                let text = this.$t("cancel");
                switch (this.$vuetify.breakpoint.name) {
                case "xs": text = "x"; break;
                case "sm": text = "x"; break;
                }
                return text;
            },

            addIng() {
                this.newIng.name = this.newIng.name.trim();
                if((this.recipe.ingredients.findIndex((ingredient) => ingredient.name === this.newIng.name) > -1) && this.oldIng.name !== this.newIng.name) {
                    this.errorIng = true;
                    this.error_code_ing = "error_duplicate_key";
                } else if(this.$refs.addIngredient[0].validate()) {
                    this.errorIng = false;
                    this.error_code_ing = "";
                    if(this.addingIngredient) {
                        this.recipe.ingredients.push(this.newIng);
                    } else {
                        this.recipe.ingredients = this.recipe.ingredients.map(ing => (ing.name === this.oldIng.name) ? this.newIng : ing);
                    }
                    this.oldIng = {};
                    this.newIng = {};
                    this.addingIngredient = false;
                    this.modifyingIngredient = false;
                    this.$refs.addIngredient[0].reset();
                }
            },

            cancelIng() {
                this.errorIng = false;
                this.$refs.addIngredient[0].reset();
                this.addingIngredient = false;
                this.modifyingIngredient = false;
            },

        },

        watch: {
            "$route.params.id": function() {
                if(!this["$route.params.id"]){
                    Object.assign(this.$data, this.$options.data.apply(this));
                    this.$refs.createRecipe.reset();
                }
            },
        },

    };
</script>

<style scoped>

    .newIngQty {
        width: 15%;
    }

    .newIngName {
        width: 85%;
    }

    #content {
        padding: 2% 3%;
        margin-top: 6%;
        margin-bottom: 2%;
        border: 1px solid rgb(64, 64, 64);
        border-radius: 25px;
        width: 50%;
    }

    .delButton:hover {
        color: black!important;
    }

    .v-list-item__content {
        align-items: unset;
        align-self: unset;
        display: unset;
    }

    @media (max-width: 960px) {

        #content {
            margin: 6% 3% 0px 3%;
            border: unset;
            width: unset;
        }
    }

    @media (max-width: 600px) {

        .small {
            width: unset;
        }

        #content {
            margin: 16% 3% 0px 3%;
            border: unset;
            width: unset;
        }

        .newIngName {
            width: 75%;
        }

        .newIngQty {
            width: 25%;
        }

        .addIng {
            min-width: unset!important;
            padding: 0px 10px!important;
            font-size: 1.1em;
        }

    }

</style>

<i18n>
{
    "en": {
        "error_duplicate_key": "This ingredient already exists",
        "add": "Add",
        "cancel": "Cancel",
        "title": "New recipe",
        "updateTitle": "Update of the recipe",
        "name": "Name", 
        "qty": "Quantity",
        "category": "Category",
        "time": "Preparation time",
        "description": "Description",
        "ingredients":"Ingredients",
        "quantity":"Quantity",
        "step":"Step",
        "no-data-available": "No categories available",
        "recipe-created": "Recipe created !",
        "recipe-updated": "Recipe updated !",
        "min": "minutes"
    },
    "fr": {
        "error_duplicate_key": "Cet ingrédient existe déjà",
        "add": "Ajouter",
        "cancel": "Annuler",
        "title": "Nouvelle recette",
        "updateTitle": "Mise à jour de la recette",
        "name": "Nom", 
        "qty": "Quantité",
        "category": "Catégorie",
        "time": "Temps de préparation",
        "description": "Description",
        "ingredients":"Ingrédients",
        "quantity":"Quantité",
        "step":"Étapes",
        "no-data-available": "Aucune catégorie disponible",
        "recipe-created": "Recette créée !",
        "recipe-updated": "Recette mise à jour !",
        "min": "minutes"
    }
}
</i18n>
