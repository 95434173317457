import Vue from "vue";
import axios from "axios";

import store from "@/store";


export default {

    /**
     * Get all categories of recipe
     */
    getCategories() {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/categories`;
        const { jwt } = store.getters["user/getToken"]();
        return axios.get(url, {
            headers: { Authorization: jwt },
        });
    },

};