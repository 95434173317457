<template>
    <div class="content">
        <Header active="login"/>
        <v-card :elevation="4" class="mainContent">
            <h2 class="connectionTitle">{{ $t('connect') }}</h2>
            <v-form ref="login">
                <v-text-field 
                    v-model="id"
                    v-bind:label="$t('username_mail')" 
                    :rules="[rules.required]" 
                    @keyup.enter="submit" 
                    required
                ></v-text-field>
                <v-text-field 
                    v-model="password"
                    v-bind:label="$t('password')" 
                    :rules="[rules.required]" 
                    type="password" 
                    @keyup.enter="submit" 
                    required
                ></v-text-field>
                <p></p>
                <p v-if="error" style="color: red">{{ $t('error_log') }}</p>
            </v-form>
            <v-card-text class="text-right">
                <v-btn color="primary" @click="submit">{{ $t('login') }}</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import Header from "@/components/login/Header";
    import { mapActions } from "vuex";

    export default {
        name: "App",

        components: {
            Header,
        },

        data () {
            return {
                id: "",
                password: "",
                rules: {
                    required: v => !!v || this.$t("required"),
                },
                error: false,
            };
        },

        methods: {
            ...mapActions("user", ["login"]),
            async submit() {
                try {
                    if(this.$refs.login.validate()) {
                        await this.login({
                            email: this.id,
                            password: this.password,
                        });
                    }
                    const redirectTo = this.$route.query.from || "/";
                    this.$router.replace(redirectTo);
                } catch (err) {
                    this.error = true;
                    this.error_code = err.response.data;
                    console.error(`Error has occured : ${err}`);
                }
            },
        },
    };
</script>

<style>
    .mainContent {
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 75vw;
        padding: 3%;
        padding-top: 2%;
        padding-bottom: 0%;
        font-family: 'Segoe UI';
    }

    .connectionTitle {
        color: #71A0FF;
    }
</style>

<style scoped>
    .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
        padding: 0px 12px 0px 12px !important;
    }
</style>

<i18n>
{
    "en": {
        "username_mail": "Mail",
        "connect": "Connection",
        "save_password": "Remember my credentials",
        "login": "Login",
        "forgotten_password": "Forgotten password ?",
        "error_log": "Username or password incorrect, please try again"
    },
    "fr": {
        "username_mail": "E-mail",
        "connect": "Connexion",
        "save_password": "Se souvenir de mon mot de passe",
        "login": "Se connecter",
        "forgotten_password": "Mot de passe oublié ?",
        "error_log": "Nom d'utilisateur ou mot de passe incorrect, veuillez réessayer"
    }
}
</i18n>