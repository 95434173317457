<template>
    <div class="content">
        <v-container class="pt-lg-0 px-0" id="main">
            <v-card class="ml-lg-6 pa-lg-6 black--text">
                <v-card-title class="pb-10 card-title">
                    <h1 class="d-flex flex-column text-wrap recipe-title">{{ selected.name }}</h1>
                    <v-spacer></v-spacer>
                    <v-btn 
                        class="d-flex flex-column delButton" 
                        icon
                        @click="removeSelectedRecipe()"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="black--text">
                    <div class="d-flex flex-column align-baseline">
                        <h2>{{ $t('description') }}</h2>
                        <p class="mt-2">{{ selected.description }}</p>
                    </div>

                    <div class="d-flex flex-row align-baseline">
                        <h3>{{ $t('category') }} </h3>
                        <p class="ml-2">{{ $t(selected.category_name) }}</p>
                    </div>

                    <div class="d-flex flex-row align-baseline">
                        <h3>{{ $t('prepTime') }}</h3>
                        <p class="ml-2">{{ selected.preparation_time }} {{ $t('min') }}</p>
                    </div>
                    
                    <h3>{{ $t('ingredient') }}</h3>
                    <v-list class="pa-0 mt-2"              
                        v-for="ing in selected.ingredients"
                        v-bind:key="ing.name"
                    >
                        <v-list-item class="pl-0">
                            <v-list-item-content class="py-1">
                                <v-list-item-title>{{ `${ing.quantity} ${ing.name}` }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <h3 class="mt-6">{{ $t('steps') }} </h3>
                    <p class="steps">{{ selected.instructions }}</p>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";

    export default {
        name: "RecipeContent",

        computed: {
            ...mapState("recipes", ["selected"]),
        },

        methods: {
            ...mapActions("recipes", ["removeSelectedRecipe"]),
        },
    };
</script>

<style scoped>

    @media only screen and (min-width: 960px) {
        #main {
            margin-top: 64px;
        }
    }

    .v-list-item {
        min-height: 16px;
    }

    .card-title {
        word-break: break-word;
    }

    .recipe-title {
        max-width: 80%;
        line-height: 1;
    }

    .steps {
        white-space: break-spaces;
    }

    .delButton:hover {
        color: black!important;
    }

</style>