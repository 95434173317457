import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import categories from "./modules/categories";
import recipes from "./modules/recipes";
import user from "./modules/user";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        categories,
        recipes,
        user,
    },
    strict: debug,
    plugins: [
        createPersistedState(),
    ],
});