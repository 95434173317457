<template>
    <div class="content">
        <Header :active="$route.params.id ? '' : 'recipes'" />
        <v-container id="main" class="d-lg-flex">
            <ListRecipes class="listRecipes"/>
            <RecipeContent v-if="selected && selected.id_recipe" id="recipeContent"/>
        </v-container>
    </div>
</template>

<script>
    import Header from "@/components/recipes/Header.vue";
    import ListRecipes from "@/components/recipes/ListRecipes.vue";
    import RecipeContent from "@/components/recipes/RecipeContent.vue";
    import { mapState } from "vuex";

    export default {
        name: "Recipes",
        components: {
            Header,
            ListRecipes,
            RecipeContent,
        },

        watch: {
            "$route.params.id": {
                handler() {
                    if(this.$route.params.id) {
                        this.$store.dispatch("recipes/getRecipesFromCategory", this.$route.params.id);
                    } else {
                        this.$store.dispatch("recipes/getAllRecipes");
                    }
                },
                immediate: true,
            },
        },

        computed: {
            ...mapState("recipes", ["selected"]),
        },
    };
</script>

<style scoped>
    .content {
        width: 100%;
    }
    .listRecipes {
        width: 35%;
        height: 100%;
    }

    @media only screen and (max-width: 960px) {
        .listRecipes {
            width: 100%;
        }
    }

</style>
