<template>
    <v-card :elevation="4">
        <v-form ref="editPassword">
            <v-row>
                <v-avatar
                    size="64"
                    class="mt-4 mt-lg-8 ml-6 ml-lg-16"
                >
                    <v-img :src="require('@/assets/icons/user.svg')"></v-img>
                </v-avatar>
                <v-card-title class="mt-3 mt-lg-6">{{ $t("password") }}</v-card-title>
            </v-row>

            <v-text-field 
                v-model="newUser.oldPassword" 
                type="password" 
                v-bind:label="$t('old_password')" 
                :rules="[rules.required]" 
                @keyup.enter="submit" 
                required
                class="mt-5 mx-6 mx-lg-16"
            ></v-text-field>

            <v-text-field 
                v-model="newUser.newPassword" 
                type="password" 
                v-bind:label="$t('new_password')" 
                :rules="[rules.required, rules.password, rules.newPassword]" 
                @keyup.enter="submit" 
                required
                class="mx-6 mx-lg-16"
            ></v-text-field>
            <p class="pass-info mx-6 mx-lg-16">{{ $t('password-l1')}}</p>
            <p class="pass-info mx-6 mx-lg-16" :class="newUser.newPassword.length > 7 ? 'correct' : 'incorrect'">{{ $t('password-l2')}}</p>
            <p class="pass-info mx-6 mx-lg-16" :class="/[A-Z]/gm.test(newUser.newPassword) ? 'correct' : 'incorrect'">{{ $t('password-l3')}}</p>
            <p class="pass-info mx-6 mx-lg-16" :class="/[a-z]/gm.test(newUser.newPassword) ? 'correct' : 'incorrect'">{{ $t('password-l4')}}</p>
            <p class="pass-info mx-6 mx-lg-16" :class="/[0-9]/gm.test(newUser.newPassword) ? 'correct' : 'incorrect'">{{ $t('password-l5')}}</p>
            <p class="pass-info mx-6 mx-lg-16" :class="/[@.!#$%&'*+/=?^_`{|}~-]/gm.test(newUser.newPassword) ? 'correct' : 'incorrect'">{{ $t('password-l6')}}</p>
            <v-text-field 
                v-model="passwordConfirmation" 
                type="password" 
                v-bind:label="$t('password-confirm')" 
                :rules="[rules.passwordMatch, rules.required]" 
                @keyup.enter="submit" 
                required
                class="mx-6 mx-lg-16"
            ></v-text-field>

            <v-row class="mx-6 mx-lg-16">
                <p v-if="error" style="color: red">{{ $t(error_code) }}</p>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" color="primary" @click="submit">{{ $t('submit') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {
        name: "EditPassword",

        computed: {
            ...mapState("user", ["user"]),
        },

        created() {
            Object.assign(this.newUser, this.user);
        },

        data() {
            return {
                newUser: {
                    name: "",
                    firstname: "",
                    email: "",
                    password: "",
                    oldPassword: "",
                    newPassword: "",
                    user_id: null,
                },
                rules: {
                    required: v => !!v || this.$t("required"),
                    newPassword: () => this.newUser.newPassword !== this.newUser.oldPassword || this.$t("password-differ"),
                    passwordMatch: () => this.newUser.newPassword === this.passwordConfirmation || this.$t("password-match"),
                    password: (password) => (password.length > 7 && /[A-Z]/gm.test(password) && /[a-z]/gm.test(password) && /[0-9]/gm.test(password) && /[@.!#$%&'*+/=?^_`{|}~-]/gm.test(password)) || this.$t("password-criteria"),
                },
                error: false,
                error_code: "",
                passwordConfirmation: "",
            };
        },

        methods: {
            ...mapActions("user", ["editPassword"]),

            async submit() {
                try {
                    if(this.$refs.editPassword.validate()) {
                        await this.editPassword(this.newUser);
                        this.$router.push("/");
                        this.$noty.success(this.$t("password-updated"));
                    }
                } catch (err) {
                    console.log(err);
                    this.error = true;
                    this.error_code = err.response && err.response.status == 403 ? this.$t("bad-password") : this.$t("error-occured");
                    console.error(`Error has occured : ${err}`);
                    this.$noty.error(this.$t("error-occured"));
                }
            },

        },
    };
</script>

<style scoped>
    .mainContent
    {
        margin-top: 2%;
    }

    .pass-info {
        font-size: 0.8em;
    }

    .pass-info.incorrect {
        color: red;
    }

    .pass-info.correct {
        color: green;
    }
</style>

<i18n>
{
    "en": {
        "bad-password": "Former password doesn't match",
        "old_password": "Old password",
        "new_password": "New password",
        "password-differ": "Password must be different from previous one",
        "password-updated": "Password updated !",
        "password-match": "Passwords must be the same",
        "password-criteria": "Respect all criterias",
        "password-confirm": "Confirm password",
        "password-l1" : "Your password must be composed of at least :",
        "password-l2" : "- 8 characters",
        "password-l3" : "- one upper case character",
        "password-l4" : "- one lower case character",
        "password-l5" : "- one number",
        "password-l6" : "- one special character (@ . ! # $ % & ' * + / = ? ^ _ ` { | } ~ -)"
    },
    "fr": {
        "bad-password": "L'ancien mot de passe ne correspond pas",
        "old_password": "Ancien mot de passe",
        "new_password": "Nouveau mot de passe",
        "password-differ": "Le mot de passe ne peut-être le même que le précédent",
        "password-updated": "Mot de passe mis à jour !",
        "password-match": "Les mots de passes doivent correspondre",
        "password-criteria": "Le mot de passe doit respecter les règles suivantes",
        "password-confirm": "Confirmer le mot de passe",
        "password-l1" : "Votre mot de passe doit être composé d'au moins :",
        "password-l2" : "- 8 caractères",
        "password-l3" : "- une lettre majuscule",
        "password-l4" : "- une lettre minuscule",
        "password-l5" : "- un chiffre",
        "password-l6" : "- un caractère spécial (@ . ! # $ % & ' * + / = ? ^ _ ` { | } ~ -)"
    }
}
</i18n>