<template>
    <div class="content">
        <Header/>
        <v-card :elevation="4" class="mainContent">
            <h2 class="connectionTitle">{{ $t('mail-sent') }}</h2>
            <div id="text">
                <p :hidden="!resent">{{ $t('mail-resent') }}</p>
                {{ $t('verified') }}
            </div>
            <v-card-actions>
                <v-btn absolute right bottom color="primary" @click="redirect">{{ $t('return-login') }}</v-btn>
                <v-btn absolute left bottom color="normal" @click="resend">{{ $t('resend-mail') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import Header from "@/components/login/Header";
    import { mapActions } from "vuex";

    export default {
        name: "Confirm",

        data() {
            return {
                resent: false,
            };
        },

        components: {
            Header,
        },

        methods: {
            ...mapActions("user", ["resendMail"]),

            redirect() {
                this.$router.push("/");
            },

            async resend() {
                try {
                    this.resent = true;
                    await this.resendMail(this.$route.params.id);
                } catch (err) {
                    console.error(`Error has occured : ${err}`);
                }
            },
        },

    };
</script>

<i18n>
{
    "en": {
        "mail-sent": "A confirmation mail has been sent",
        "return-login": "Return to login screen",
        "verified": "A confirmation mail has been sent to the adress provided. Click on the link in the mail to confirm your account and start using Coquinaria.",
        "resend-mail": "Resend the mail",
        "mail-resent": "Mail resent !"
    },
    "fr": {
        "mail-sent": "Un mail de confirmation a été envoyé",
        "return-login": "Retour à l'écran de connexion",
        "verified": "Un mail de confirmation a été envoyée à l'adresse saisie. Cliquez sur le lien dans le mail pour confirmer votre compte et commencer à utiliser Coquinaria.",
        "resend-mail": "Renvoyer le mail",
        "mail-resent": "Mail renvoyé !"
    }
}
</i18n>

<style scoped>
    h2 {
        margin-bottom: 3%;
    }

    #text {
        margin-bottom: 5%;
    }

    p {
        color: red;
        font-weight: bold;
    }
</style>