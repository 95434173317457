<template>
    <div class="content">
        <Header :active="$route.params.action ? $route.params.action : 'account'" />
        <v-container id="main">
            <v-layout>
                <v-flex>
                    <v-card
                        height="100%"
                        :width="width"
                        elevation="0"
                    >
                        <v-navigation-drawer permanent v-if="$vuetify.breakpoint.name !== 'xs'">
                            <v-list
                                dense
                                nav
                            >
                                <v-list-item-group
                                    v-model="selectedItem"
                                    mandatory
                                    color="primary"
                                >
                                    <v-list-item
                                        v-for="item in items"
                                        :key="item.title"
                                    >
                                        <v-list-item-icon v-if="$vuetify.breakpoint.name !== 'sm'">
                                            <v-icon v-text="item.icon"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-navigation-drawer>
                    </v-card>
                </v-flex>
                <v-flex>
                    <EditProfile v-if="selectedItem == 0" class="mt-4 mt-sm-1" />
                    <EditPassword v-if="selectedItem == 1" class="mt-4 mt-sm-1" />
                </v-flex>
                <v-spacer></v-spacer>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import Header from "@/components/recipes/Header.vue";
    import EditProfile from "@/components/account/EditProfile.vue";
    import EditPassword from "@/components/account/EditPassword.vue";

    export default {
        name: "Account",

        components: {
            Header,
            EditProfile,
            EditPassword,
        },

        computed: {
            width () {
                let width = "100px";
                switch (this.$vuetify.breakpoint.name) {
                case "xs": width = "15px"; break;
                case "sm": width = "150px"; break;
                case "md": width = "200px"; break;
                case "lg": width = "256px"; break;
                case "xl": width = "256px"; break;
                }
                return width;
            },
        },

        data() {
            return {
                items: [
                    { title: "account", icon: "mdi-account-circle" },
                    { title: "password", icon: "mdi-lock" },
                ],
                selectedItem: 0,
            };
        },

        watch: {
            "$route.params.action": {
                handler() {
                    if(this.$route.params.action === "profile") {
                        this.selectedItem = 0;
                    } else if (this.$route.params.action === "password") {
                        this.selectedItem = 1;
                    }
                },
                immediate: true,
            },
        },

    };
</script>

<style scoped>

    #main {
        margin-top: 60px;
        margin-left: 0;
        padding-left: 0;
    }

</style>

<i18n>
{
    "en": {
        "profile-updated": "Profile updated !",
        "password-match": "Passwords must be the same",
        "password-criteria": "Respect all criterias",
        "password-confirm": "Confirm password",
        "not-email": "Please enter a valid mail"
    },
    "fr": {
        "profile-updated": "Profil mis à jour !",
        "password-match": "Les mots de passes doivent correspondre",
        "password-criteria": "Le mot de passe doit respecter les règles suivantes",
        "password-confirm": "Confirmer le mot de passe",
        "not-email": "Veuillez saisir une adresse mail valide"
    }
}
</i18n>