<template>
    <div id="app">
        <v-app>
            <router-view/>
        </v-app>
    </div>
</template>

<script>
    export default {
        name: "App",

        mounted() {
            // Load theme from storage if existing
            const theme = localStorage.getItem("dark_theme");
            if (theme) {
                if (theme === "true") {
                    this.$vuetify.theme.dark = true;
                } else {
                    this.$vuetify.theme.dark = false;
                }
            } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                this.$vuetify.theme.dark = true;
                localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
            }

            // Load language from storage if existing
            const language = localStorage.getItem("language");
            if(language) {
                this.$root.$i18n.locale = language;
            }
        },

        watch: {
            "$vuetify.theme.dark": function() {
                let elHtml = document.getElementsByTagName("html")[0];
                if(this.$vuetify.theme.dark) elHtml.style.backgroundColor = "#121212";
                else elHtml.style.backgroundColor = "#fff";
            },
        },

    };
</script>

<style scoped>
#app {
    height: 100vh;
    font-family: 'Roboto';
}
</style>