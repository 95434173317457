<template>
    <div class="content">
        <Header/>
        <v-card :elevation="4" class="mainContent">
            <h2 class="connectionTitle">{{ $t('account-verified') }}</h2>
            <div id="text">
                {{ $t('verified') }}
            </div>
            <v-card-actions>
                <v-btn absolute right bottom color="primary" @click="redirect">{{ $t('return-login') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import Header from "@/components/login/Header";
    import { mapActions } from "vuex";

    export default {
        name: "Verify",

        components: {
            Header,
        },

        methods: {
            ...mapActions("user", ["verifyAccount"]),

            redirect() {
                this.$router.push("/");
            },
        },

        async mounted() {
            try {
                await this.verifyAccount(this.$route.params.id);
            } catch (err) {
                console.error(`Error has occured : ${err}`);
            }
        },
    };
</script>

<i18n>
{
    "en": {
        "account-verified": "Your account has been successfully verified !",
        "return-login": "Return to login screen",
        "verified": "Your account has been verified. You can now return to the login screen and access Coquinaria."
    },
    "fr": {
        "account-verified": "Votre compte a bien été vérifié !",
        "return-login": "Retour à l'écran de connexion",
        "verified": "Votre compte a bien été vérifié. Vous pouvez désormais vous connecter et accéder à Coquinaria."
    }
}
</i18n>

<style scoped>
    h2 {
        margin-bottom: 5%;
    }

    #text {
        margin-bottom: 5%;
    }
</style>