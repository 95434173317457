import Vue from "vue";
import axios from "axios";
import store from "@/store";

export default {

    /**
     * Call the API to create a user
     * @param {Object} user User to create
     */
    createUser(user) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/register`;
        return axios.post(url, user);
    },

    /**
     * Call the API to verify a user
     * @param {Number} id ID of the user
     */
    verifUser(id) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/verif/${id}`;
        return axios.get(url).then(response => response.data);
    },

    /**
     * Call the API to resend a mail to confirm an account
     * @param {Number} id ID of the user
     */
    resendMail(id) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/resendMail/${id}`;
        return axios.get(url).then(response => response.data);
    },

    /**
     * Call the API to log
     * @param {Object} user User to log
     */
    login(user) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/login`;
        return axios.post(url, user);
    },

    /**
     * Call the API to update an user data
     * @param {Object} user New user data
     */
    updateUser(user) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/user`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.put(url, user, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Call the API to update an user password
     * @param {Object} user New user password
     */
    updatePassword(user) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/user/password`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.put(url, user, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

};