<template>
    <div class="content">
        <Header active="categories" />
        <v-container id="main">
            <v-layout row wrap>
                <v-flex 
                    xs12 sm6 md4 lg4 xl4
                    v-for="categorie in categories"
                    v-bind:key="categorie.id_category"
                >
                    <v-card
                        class="mx-auto my-6"
                        max-width="75%"
                    >
                        <v-img
                            :src="require(`@/assets/${categorie.name}.png`)"
                            :height="imageHeight"
                        ><v-card-title>{{ $t(categorie.name) }}</v-card-title></v-img>
                        <v-card-actions>
                            <v-btn
                                color="blue"
                                text
                                @click="seeRecipes(categorie.id_category)"
                            >{{ $t("see_recipes") }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import Header from "@/components/recipes/Header.vue";

    import { mapState } from "vuex";

    export default {
        name: "Categories",
        components: {
            Header,
        },
        computed: {
            ...mapState("categories", ["categories"]),
            imageHeight() {
                let height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                case "xs": height = "200px"; break;
                case "sm": height = "170px"; break;
                case "md": height = "200px"; break;
                case "lg": height = "300px"; break;
                case "xl": height = "300px"; break;
                }
                return height;
            },
        },

        methods: {
            seeRecipes(id_category) {
                this.$router.push(`/recipes/${id_category}`);
            },
        },

        created() {
            this.$store.dispatch("categories/getAllCategories");
        },
    };
</script>

<style scoped>

    #main {
        margin-top: 64px;
    }

    .v-card__title {
        font-size: 1.5rem;
    }

</style>

<i18n>
{
    "en": {
        "see_recipes": "See recipes"
    },
    "fr": {
        "see_recipes": "Voir les recettes"
    }
}
</i18n>