<template>
    <div class="content">
        <Header :active="$route.params.id ? '' : 'recipes'" />
        <v-container id="main">
            <p v-if="!error">{{ $t("add-shared-success")}}</p>
            <p v-else>{{ $t("add-shared-error")}}</p>
        </v-container>
    </div>
</template>

<script>
    import Header from "@/components/recipes/Header.vue";
    import RecipesAPI  from "@/api/RecipesAPI";

    export default {
        name: "AddShareRecipe",
        components: {
            Header,
        },

        data() {
            return {
                error: false,
            };
        },

        async mounted() {
            try {
                await RecipesAPI.addSharedRecipe(this.$route.params.id);
                setTimeout(() => {
                    this.$router.push("/");
                }, 3000);
            } catch (err) {
                console.error(`Error has occured : ${err}`);
                this.error = true;
            }
        },
    };
</script>

<style scoped>

    #main {
        margin-top: 80px;
    }

</style>

<i18n>
{
    "fr": {
        "add-shared-success": "La recette a été ajoutée avec succès, vous allez être redirigé vers la page d'accueil.",
        "add-shared-error": "Erreur lors de l'ajout de la recette, veuillez réessayer."
    },
    "en": {
        "add-shared-success": "The recipe has been added successfully, you will be redirected to the home page.",
        "add-shared-error": "Error adding recipe, please try again."
    }
}
</i18n>