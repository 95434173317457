<template>
    <v-card :elevation="4">
        <v-form ref="editProfile">
            <v-row>
                <v-avatar
                    size="64"
                    class="mt-4 mt-lg-8 ml-6 ml-lg-16"
                >
                    <v-img :src="require('@/assets/icons/user.svg')"></v-img>
                </v-avatar>
                <v-card-title class="mt-3 mt-lg-6">{{ $t("account") }}</v-card-title>
            </v-row>

            <v-row class="mx-2 mx-lg-12">
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="newUser.firstname"
                        v-bind:label="$t('firstname')" 
                        :rules="[rules.required]"
                        @keyup.enter="submit"
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="newUser.name"
                        v-bind:label="$t('name')" 
                        :rules="[rules.required]"
                        @keyup.enter="submit"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mx-2 mx-lg-12">
                <v-col
                    cols="12"
                >
                    <v-text-field
                        v-model="newUser.email"
                        v-bind:label="$t('mail')" 
                        :rules="[rules.required, rules.adrMel]"
                        @keyup.enter="submit"
                    ></v-text-field>
                </v-col>
            </v-row>
            
            <v-row class="mx-2 mx-lg-12">
                <p v-if="error" style="color: red">{{ $t(error_code) }}</p>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" color="primary" @click="submit">{{ $t('submit') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {
        name: "EditProfile",

        computed: {
            ...mapState("user", ["user"]),
        },

        created() {
            Object.assign(this.newUser, this.user);
        },

        data() {
            return {
                newUser: {
                    name: "",
                    firstname: "",
                    email: "",
                    user_id: null,
                },
                rules: {
                    required: v => !!v || this.$t("required"),
                    adrMel: () =>  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gmi.test(this.newUser.email) || this.$t("not-email"),
                },
                error: false,
                error_code: "",
            };
        },

        methods: {
            ...mapActions("user", ["editProfile"]),

            async submit() {
                try {
                    if(this.$refs.editProfile.validate()) {
                        await this.editProfile(this.newUser);
                        this.$router.push("/");
                        this.$noty.success(this.$t("profile-updated"));
                    }
                } catch (err) {
                    console.log(err);
                    this.error = true;
                    this.error_code = err;
                    console.error(`Error has occured : ${err}`);
                    this.$noty.error(this.$t("error-occured"));
                }
            },

        },
    };
</script>

<i18n>
{
    "en": {
        "profile-updated": "Profile updated !",
        "not-email": "Please enter a valid mail"
    },
    "fr": {
        "profile-updated": "Profil mis à jour !",
        "not-email": "Veuillez saisir une adresse mail valide"
    }
}
</i18n>