import RecipesAPI from "@/api/RecipesAPI.js";

// initial state
const state = () => ({
    recipes: [],
    selected: {},
    modified: {},
    filteredRecipes: [],
});

// getters
const getters = {
    
    getSelectedRecipe: state => {
        return state.selected;
    },

    getRecipes: state => {
        return state.recipes;
    },

    getFilteredRecipes: state => {
        return state.filteredRecipes;
    },
};

// actions
const actions = {

    /**
     * Get all recipes
     * @param {*} param0 
     */
    async getAllRecipes ({ commit }) {
        commit("setRecipes", {});
        let { data } = await RecipesAPI.getRecipes();
        commit("setRecipes", data);
        commit("setFilteredRecipes", []);
    },

    /**
     * Get one recipe with an id
     * @param {*} param0 
     * @param {*} recipeId 
     */
    async getRecipe({ commit }, recipeId) {
        let { data } = await RecipesAPI.getRecipe(recipeId);
        commit("setSelectedRecipe", data);
    },

    /**
     * Get one recipe with an id
     * @param {*} param0 
     * @param {*} recipeId 
     */
     async modifyRecipe({ commit }, recipeId) {
        let { data } = await RecipesAPI.getRecipe(recipeId);
        commit("setModifiedRecipe", data);
    },

    /**
     * Get all recipes from a category
     * @param {*} param0 
     * @param {*} categoryId Id of the category
     */
    async getRecipesFromCategory ({ commit }, categoryId) {
        commit("setRecipes", {});
        let { data } = await RecipesAPI.getRecipesFromCategorie(categoryId);
        commit("setRecipes", data);
        commit("setFilteredRecipes", []);
    },

    /**
     * Create a recipe
     * @param {*} param0 
     * @param {*} recipe Recipe to create
     */
    async createRecipe ({ commit }, recipe) {
        await RecipesAPI.createRecipe(recipe);
        commit("addRecipe", recipe);
    },

    /**
     * Update a recipe
     * @param {*} param0 
     * @param {*} recipe Recipe to update
     */
    async updateRecipe ({ commit, getters }, recipe) {
        await RecipesAPI.updateRecipe(recipe);
        commit("updateRecipe", recipe);

        // Selected reciped is being updated
        if(getters.getSelectedRecipe && recipe.id_recipe == getters.getSelectedRecipe.id_recipe)
            await commit("setSelectedRecipe", recipe);
    },

    /**
     * Delete a recipe then reload all recipes
     * @param {*} context 
     * @param {*} params.recipeId ID of recipe to delete
     * @param {*} params.categoryId category ID to reload (leave undefined for all recipes)
     */
    async deleteRecipe({ getters, dispatch, commit }, { recipeId, categoryId }) {
        // Delete the recipe
        await RecipesAPI.deleteRecipe(recipeId);
        
        // Unselect the recipe if this is the deleted one
        if(getters.getSelectedRecipe && recipeId == getters.getSelectedRecipe.id_recipe)
            await commit("setSelectedRecipe", {});

        // Refresh the recipes list (from category or all recipes)
        if(categoryId)
            await dispatch("getRecipesFromCategory", categoryId);
        else
            await dispatch("getAllRecipes");
    },

    /**
     * Remove the selected recipe
     * @param {*} param0 
     */
    removeSelectedRecipe ({ commit }) {
        commit("setSelectedRecipe", {});
    },

    /**
     * Search a recipe using its name
     * @param {*} param0 
     * @param {*} searchFilter Name to search
     */
    searchRecipe ({ commit, getters }, searchFilter) {
        let allRecipes = getters.getFilteredRecipes.concat(getters.getRecipes);
        searchFilter = searchFilter || "";

        // Sort by alphabetical order
        allRecipes = allRecipes.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (b.name > a.name) {
                return 1;
            }
            return 0;
        });

        let filteredRecipes = [];
        let recipes = [];

        // Filter recipes
        allRecipes.map((recipe) => {
            (recipe.name).toLowerCase().includes(searchFilter.toLowerCase()) ? recipes.push(recipe) : filteredRecipes.push(recipe);
        });
        
        commit("setRecipes", recipes);
        commit("setFilteredRecipes", filteredRecipes);
    },

    /**
     * Share a recipe to an other user
     * @param {*} param0 
     * @param {*} recipe Recipe to share
     */
    async shareRecipe ({ dispatch }, recipe) {
        await RecipesAPI.shareRecipe(recipe);

        // Refresh the recipes list (from category or all recipes)
        if(recipe.id_category)
            await dispatch("getRecipesFromCategory", recipe.id_category);
        else
            await dispatch("getAllRecipes");
    },

};

// mutations
const mutations = {

    setRecipes (state, recipes) {
        state.recipes = recipes;
    },

    setSelectedRecipe (state, recipe) {
        state.selected = recipe;
    },
    
    setModifiedRecipe (state, recipe) {
        state.modified = recipe;
    },

    setFilteredRecipes (state, filteredRecipes) {
        state.filteredRecipes = filteredRecipes;
    },

    addRecipe (state, recipe) {
        state.recipes.push(recipe);
    },

    updateRecipe (state, recipe) {
        state.recipes = state.recipes.map(r => (r.id_recipe === recipe.id_recipe) ? recipe : r);
        state.modified = {};
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};