<template>
    <div class="content">
        <!-- Bouton back to Top -->
        <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
        >
            <v-icon>mdi-arrow-up</v-icon>
        </v-btn>

        <!-- Zone Recherche d'une recette -->
        <v-text-field
            v-model="searchFilter"
            v-bind:label="$t('name')" 
            clearable
            @input="searchRecipe(searchFilter)"
            prepend-icon="mdi-magnify"
        >

        </v-text-field>

        <!-- Liste recettes -->
        <p v-if="recipes.length == 0">{{ $t("no-recipe") }}</p>
        <v-expansion-panels v-else focusable hover class="recipes">
            <v-expansion-panel v-for="recipe in recipes" v-bind:key="recipe.id_recipe">
                <v-expansion-panel-header>
                    {{ recipe.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p class="body-2 mt-4">
                        <span class="body-1 font-weight-bold">{{ $t("description") }}</span> : {{ recipe.description }}
                    </p>
                    <p class="body-2 mt-4">
                        <span class="body-1 font-weight-bold">{{ $t("prep_time") }}</span> : {{ `${recipe.preparation_time} ${$t("min")}` }}
                    </p>
                    <p class="body-2 mt-4 mb-2">
                        <span class="body-1 font-weight-bold">{{ $t("tools") }}</span> :
                    </p>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs} ">
                            <v-btn
                                dark
                                fab
                                small
                                color="blue"
                                v-bind="attrs"
                                v-on="on"
                                class="ml-3"
                                @click="selectRecipe(recipe.id_recipe)"
                            >
                                <v-icon >mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("see") }}</span>
                    </v-tooltip>
                    <!-- Delete recipe -->
                    <v-dialog
                        v-model="deleteDialog"
                        max-width="350"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-tooltip bottom v-bind="attrs" v-on="on">
                                <template v-slot:activator="{on, attrs} ">
                                    <v-btn
                                        dark
                                        fab
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        class="ml-3"
                                        @click="deleteDialog = true"
                                    >
                                        <v-icon >mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("delete") }}</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5">{{ $t("deleteAsking") }}</v-card-title>
                            <v-card-text>{{ $t("deleteIrreversible") }}</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    dark
                                    color="secondary"
                                    x-large
                                    @click="deleteDialog = false"
                                >
                                    {{ $t("no") }}
                                </v-btn>
                                <v-btn
                                    dark
                                    color="red"
                                    x-large
                                    @click="confirmDeleteRecipe({recipeId: recipe.id_recipe, categoryId: $route.params.id,})"
                                >
                                    {{ $t("yes") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- Share recipe -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs} ">
                            <v-btn
                                dark
                                fab
                                small
                                v-bind="attrs"
                                v-on="on"
                                color="grey"
                                class="ml-3"
                                @click="modifyRecipe(recipe.id_recipe)"
                            >
                                <v-icon >mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("modify") }}</span>
                    </v-tooltip>
                    <v-dialog
                        v-model="shareDialog"
                        max-width="350"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-tooltip bottom v-bind="attrs" v-on="on">
                                <template v-slot:activator="{on, attrs} ">
                                    <v-btn
                                        dark
                                        fab
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="black"
                                        class="ml-3"
                                        @click="shareDialog = true"
                                    >
                                        <v-icon >mdi-email</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("share") }}</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5">{{ $t("share") }}</v-card-title>
                            <v-card-text class="pb-0">{{ $t("share_recipe") }}</v-card-text>
                            <v-form ref="shareRecipe">
                                <v-text-field
                                    v-model="mail"
                                    v-bind:label="$t('mail')" 
                                    @keyup.enter="confirmShareRecipe(recipe.id_recipe)" 
                                    :rules="[rules.required, rules.adrMel]"
                                    class="mx-6"
                                ></v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    dark
                                    color="secondary"
                                    x-large
                                    @click="shareDialog = false;mail = ''"
                                >
                                    {{ $t("cancel") }}
                                </v-btn>
                                <v-btn
                                    dark
                                    color="blue"
                                    x-large
                                    @click="confirmShareRecipe(recipe.id_recipe)"
                                >
                                    {{ $t("submit") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";

    export default {
        name: "ListRecipes",

        data () {
            return {
                deleteDialog: false,
                shareDialog: false,
                fab: false,
                searchFilter: "",
                mail: "",
                rules: {
                    required: v => !!v || this.$t("required"),
                    adrMel: () =>  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gmi.test(this.mail) || this.$t("not-email"),
                },
            };
        },

        computed: {
            ...mapState("recipes", ["recipes"]),
        },

        watch: {
            "$route.params.id": function () {
                this.searchFilter = "";
            },
        },

        methods: {

            ...mapActions("recipes", ["getRecipe", "deleteRecipe", "searchRecipe", "shareRecipe"]), 
            confirmDeleteRecipe(params) {
                this.deleteDialog = false;
                this.deleteRecipe(params);
            },

            async confirmShareRecipe(id_recipe) {
                try {
                    if(this.$refs.shareRecipe[0].validate()) {
                        await this.shareRecipe({
                            id_recipe: id_recipe, 
                            id_category: this.$route.params.id,
                            mail: this.mail,
                        });
                        this.$noty.success(this.$t("recipe-shared"));
                        this.shareDialog = false;
                        this.mail = "";
                    }
                } catch (err) {
                    console.error(`Error has occured : ${err}`);
                    this.$noty.error(this.$t("error-occured"));
                }
            },

            async selectRecipe(id_recipe) {
                await this.getRecipe(id_recipe);
                this.$vuetify.goTo("#recipeContent",  {
                    offset: 50,
                });
            },

            async modifyRecipe(id_recipe) {
                this.$router.push(`/modifyRecipe/${id_recipe}`);
            },

            onScroll (e) {
                if (typeof window === "undefined") return;
                const top = window.pageYOffset ||   e.target.scrollTop || 0;
                this.fab = top > 20;
            },

            toTop () {
                this.$vuetify.goTo(0);
            },
        },
    };
</script>

<style scoped>
    .content {
        margin-top: 64px;
    }

    .recipes {
        border: 1px solid black;
    }
</style>

<i18n>
{
    "en": {
        "no-recipe": "No recipe found",
        "description": "Description",
        "prep_time": "Preparation Time",
        "tools": "Tools",
        "min": "minutes",
        "see": "See recipe",
        "delete": "Delete recipe",
        "modify": "Modify recipe",
        "share": "Send by email",
        "share_recipe": "Which email address would you like to send the recipe to ?",
        "not-email": "Please enter a valid mail",
        "recipe-shared": "Recipe shared with success",
        "deleteAsking" : "Delete this recipe?",
        "deleteIrreversible" : "This action is irreversible.",
        "yes": "Yes",
        "no": "No"
    },
    "fr": {
        "no-recipe": "Aucune recette trouvée",
        "description": "Description",
        "prep_time": "Temps de préparation",
        "tools": "Outils",
        "min": "minutes",
        "see": "Voir la recette",
        "delete": "Supprimer la recette",
        "modify": "Modifier la recette",
        "share": "Envoyer par email",
        "share_recipe": "A quelle adresse mail souhaitez-vous envoyer la recette ?",
        "not-email": "Veuillez saisir une adresse mail valide",
        "recipe-shared": "Recette partagée avec succès !",
        "deleteAsking" : "Supprimer cette recette ?",
        "deleteIrreversible" : "Cette action est irreversible.",
        "yes": "Oui",
        "no": "Non"
    }
}
</i18n>
