import UserAPI from "@/api/UserAPI.js";

import router  from "@/router";

// initial state
const state = () => ({
    user: {},
    token: {},
    userId: null,
});
  
// getters
const getters = {
    getToken: (state) => () => {
        return state.token;
    },

    getUser: (state) => () => {
        return state.user;
    },
};

// actions
const actions = {

    async login({ commit }, user) {
        let { data } = await UserAPI.login(user);

        commit("setUser", data.account); 
        commit("setToken", { jwt: data.jwt, expiry: new Date().getTime() + 1000 * 3600 * 6 });

    },

    logout({ commit, dispatch }) {
        commit("setUser", {});
        commit("setToken", {});
        dispatch("recipes/removeSelectedRecipe", null, { root: true });

        router.push("/login");
    },

    async register({ commit }, user) {
        let { data } = await UserAPI.createUser(user);
        
        commit("setUserId", data.id_user);

        return data.id_user;
    },

    async resendMail({ commit }, id_user) {
        await UserAPI.resendMail(id_user);
        
        commit("setUserId", id_user);
    },

    async verifyAccount({ commit }, id_user) {
        await UserAPI.verifUser(id_user);
        
        commit("setUserId", id_user);
    },

    async editProfile({ commit }, user) {
        const { data } = await UserAPI.updateUser(user);

        commit("setUser", data);
    },

    async editPassword({ commit }, user) {
        const { data } = await UserAPI.updatePassword(user);

        commit("setUser", data);
    },

};

// mutations
const mutations = {

    setUser (state, user) {
        state.user = user;
    },

    setToken (state, token) {
        state.token = token;
    },

    setUserId (state, userId) {
        state.userId = userId;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};