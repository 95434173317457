import Vue from "vue";
import VueRouter from "vue-router";

import Categories from "@/views/recipes/Categories.vue";
import Recipes from "@/views/recipes/Recipes.vue";
import createRecipe from "@/views/recipes/createRecipe.vue";
import AddShareRecipe from "@/views/recipes/AddShareRecipe.vue";

import Login from "@/views/login/Login.vue";

import Signup from "@/views/signup/Signup.vue";
import Verify from "@/views/signup/Verify.vue";
import Confirm from "@/views/signup/Confirm.vue";

import Account from "@/views/account/Account.vue";

import CGU from "@/views/help/CGU.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/categories",
    },
    {
        path: "*",
        redirect: "/",
    },
    {
        path: "/categories",
        name: "Categories",
        component: Categories,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/recipes/:id?",
        name: "Recipes",
        component: Recipes,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/addSharedRecipe/:id",
        name: "Add shared recipe",
        component: AddShareRecipe,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/register",
        name: "Signup",
        component: Signup,
    },
    {
        path: "/help/conditions_of_use",
        name: "Conditions of Use",
        component: CGU,
    },
    {
        path: "/verify/:id",
        name: "Verify User",
        component: Verify,
    },
    {
        path: "/confirmAccount/:id",
        name: "Confirm account",
        component: Confirm,
    },
    {
        path: "/createRecipe",
        name: "Create recipe",
        component: createRecipe,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/modifyRecipe/:id",
        name: "Modify recipe",
        component: createRecipe,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/account/:action",
        name: "Account",
        component: Account,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    try {
        if(to.matched.some(record => record.meta.requiresAuth)) {
            const token = store.getters["user/getToken"]();
            if(!token || !token.expiry) {
                next({name: "Login", query: { from: to.path }});
                return;
            }
            let { expiry } = token;
            if(new Date().getTime() > expiry) {
                store.dispatch("user/logout");
                next({name: "Login", query: { from: to.path }});
                return;
            }
            next();
        } else {
            next();
        }
    } catch (error) {
        console.error(error);
        next({name: "Login"});
    }
});

export default router;