<template>
    <div>
        <ul class="navbar">
            <router-link to="/login" class="tabs" :class="active === 'login' ? 'active' : ''" tag="li">{{ this.$t("login") }}</router-link>
            <router-link to="/register" class="tabs" :class="active === 'signup' ? 'active' : ''" tag="li">{{ this.$t("signup") }}</router-link>
        </ul>
    </div>
</template>

<style>
    .navbar {
        display: flex;
        background-color: #404040;
        width: 100%;
        padding-right: 2%;
        justify-content: flex-end;
    }

    .tabs{
        list-style: none;
        padding: 1.5%;
        color: white;
        font-family: 'Segoe UI';
        cursor:pointer;
        width: fit-content;
    }

    .tabs:hover, .tabs:active{
        box-shadow: 0 -4px 0 rgb(113, 160, 255) inset;
        background-color: #606060;
    }
    
    .tabs.active {
        box-shadow: 0 -4px 0 rgb(113, 160, 255) inset;
    }

    @media screen and (max-width: 425px) {
        .tabs {
            padding: 3.5%
        }
    }
</style>

<script>
    export default {
        name: "Header",

        props: {
            active: String,
        },
    };
</script>

<i18n>
{
    "en": {
        "signup": "Signup",
        "login": "Login"
    },
    "fr": {
        "signup": "S'inscrire",
        "login": "Se connecter"
    }
}
</i18n>