import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";

import VueNoty from "vuejs-noty";
import "vuejs-noty/dist/vuejs-noty.css";

Vue.use(VueNoty);

Vue.config.productionTip = false;

fetch(`${process.env.BASE_URL}config/config.json?t=${new Date().getTime()}`)
  .then((response) => response.json())
  .then((config) => {
        Vue.prototype.$config = config;
        new Vue({
            router,
            vuetify,
            i18n,
            store,
            render: h => h(App),
        }).$mount("#app");
    });