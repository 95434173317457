import Vue from "vue";
import axios from "axios";
import store from "@/store";

export default {
    
    /**
     * Get all the recipes from a category
     * @param {String} id ID of category
     */
    getRecipesFromCategorie(id) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/categories/${id}/recipes`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.get(url, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Get all recipes
     */
    getRecipes() {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/recipes`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.get(url, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Get informations about a precise recipe
     * @param {String} id ID of recipe
     */
    getRecipe(id) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/recipes/${id}`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.get(url, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Create a recipe
     * @param {Object} recipe Recipe to create
     */
    createRecipe(recipe) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/recipe`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.post(url, recipe, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Update a recipe
     * @param {Object} recipe Recipe to update
     */
    updateRecipe(recipe) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/recipes/${recipe.id_recipe}`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.put(url, recipe, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Delete a recipe
     * @param {String} recipeId ID of Recipe to delete
     */
    deleteRecipe(id) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/recipes/${id}`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.delete(url, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Share a recipe
     * @param {Object} recipe Recipe to share
     */
    shareRecipe(recipe) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/recipes/send/${recipe.id_recipe}`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.post(url, recipe, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

    /**
     * Add a recipe shared
     * @param {String} id ID of the recipe to add
     */
    addSharedRecipe(id) {
        const API_URL = Vue.prototype.$config.API_BACKEND;
        const url = `${API_URL}/recipes/addSharedRecipe/${id}`;
        const { jwt } = store.getters["user/getToken"]();
        const { id_user } = store.getters["user/getUser"]();
        return axios.post(url, null, {
            headers: { 
                Authorization: jwt,
                userid: id_user, 
            },
        });
    },

};