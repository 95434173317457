<template>
    <div class="content">
        <Header active="signup"/>
        <v-card :elevation="4" class="mainContent">
            <h2 class="connectionTitle">{{ $t('register') }}</h2>
            <v-form ref="signup">
                <v-text-field 
                    v-model="name" 
                    v-bind:label="$t('name')" 
                    :rules="[rules.required]" 
                    @keyup.enter="submit" 
                    required
                ></v-text-field>
                <v-text-field 
                    v-model="firstname" 
                    v-bind:label="$t('firstname')" 
                    :rules="[rules.required]" 
                    @keyup.enter="submit" 
                    required
                ></v-text-field>
                <v-text-field 
                    v-model="mail" 
                    v-bind:label="$t('mail')" 
                    :rules="[rules.required, rules.adrMel]" 
                    @keyup.enter="submit" 
                    required
                ></v-text-field>
                <v-text-field 
                    v-model="password" 
                    type="password" 
                    v-bind:label="$t('password')" 
                    :rules="[rules.required, rules.password]" 
                    @keyup.enter="submit" 
                    required
                ></v-text-field>
                <p class="pass-info">{{ $t('password-l1')}}</p>
                <p class="pass-info" :class="password.length > 7 ? 'correct' : 'incorrect'">{{ $t('password-l2')}}</p>
                <p class="pass-info" :class="/[A-Z]/gm.test(password) ? 'correct' : 'incorrect'">{{ $t('password-l3')}}</p>
                <p class="pass-info" :class="/[a-z]/gm.test(password) ? 'correct' : 'incorrect'">{{ $t('password-l4')}}</p>
                <p class="pass-info" :class="/[0-9]/gm.test(password) ? 'correct' : 'incorrect'">{{ $t('password-l5')}}</p>
                <p class="pass-info" :class="/[@.!#$%&'*+/=?^_`{|}~-]/gm.test(password) ? 'correct' : 'incorrect'">{{ $t('password-l6')}}</p>
                <v-text-field 
                    v-model="password2" 
                    type="password" 
                    v-bind:label="$t('password-confirm')" 
                    :rules="[rules.passwordMatch, rules.required]" 
                    @keyup.enter="submit" 
                    required
                ></v-text-field>
                <v-checkbox v-model="cgu" :rules="[rules.required]">
                    <template v-slot:label>
                        <div>
                            {{ $t('accept-cgu') }}
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a
                                        target="_blank"
                                        href="/help/conditions_of_use"
                                        @click.stop
                                        v-on="on"
                                    >
                                        {{ $t('cgu') }}
                                    </a>
                                </template>
                                {{ $t('new-tab') }}
                            </v-tooltip>
                        </div>
                    </template>
                </v-checkbox>
                <p v-if="error" style="color: red">{{ $t(error_code) }}</p>
            </v-form>
            <v-card-actions class="text-left">
                <v-btn class="ma-2" color="normal" @click="cancel">{{ $t('cancel') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" color="primary" @click="submit">{{ $t('submit') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import Header from "@/components/login/Header";
    import { mapActions } from "vuex";

    export default {
        name: "Signup",

        components: {
            Header,
        },

        data () {
            return {
                name: "",
                firstname: "",
                mail: "",
                password: "",
                password2: "",
                cgu: false,
                rules: {
                    required: v => !!v || this.$t("required"),
                    passwordMatch: () => this.password === this.password2 || this.$t("password-match"),
                    password: () => (this.password.length > 7 && /[A-Z]/gm.test(this.password) && /[a-z]/gm.test(this.password) && /[0-9]/gm.test(this.password) && /[@.!#$%&'*+/=?^_`{|}~-]/gm.test(this.password)) || this.$t("password-criteria"),
                    adrMel: () =>  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gmi.test(this.mail) || this.$t("not-email"),
                },
                error: false,
                error_code: "",
            };
        },

        methods: {
            ...mapActions("user", ["register"]),

            async submit() {
                try {
                    if(this.$refs.signup.validate()) {
                        let userId = await this.register({
                            name: this.name,
                            firstname: this.firstname,
                            password: this.password,
                            email: this.mail,
                        });
                        this.$router.push(`/confirmAccount/${userId}`);
                    }
                } catch (err) {
                    this.error = true;
                    this.error_code = err.response.data;
                    console.error(`Error has occured : ${err}`);
                }
            },
            cancel() {
                this.$router.push("/");
            },
        },
    };
</script>

<style scoped>
    .mainContent
    {
        margin-top: 2%;
    }

    .pass-info {
        font-size: 0.8em;
    }

    .pass-info.incorrect {
        color: red;
    }

    .pass-info.correct {
        color: green;
    }
</style>

<i18n>
{
    "en": {
        "register": "Register",
        "password-match": "Passwords must be the same",
        "password-criteria": "Respect all criterias",
        "password-confirm": "Confirm password",
        "not-email": "Please enter a valid mail",
        "accept-cgu": "Accept terms of the ",
        "cgu": "General Conditions of Use",
        "password-l1" : "Your password must be composed of at least :",
        "password-l2" : "- 8 characters",
        "password-l3" : "- one upper case character",
        "password-l4" : "- one lower case character",
        "password-l5" : "- one number",
        "password-l6" : "- one special character (@ . ! # $ % & ' * + / = ? ^ _ ` { | } ~ -)",
        "This adress already exists": "This adress already exists"
    },
    "fr": {
        "register": "Inscription",
        "password-match": "Les mots de passes doivent correspondre",
        "password-criteria": "Le mot de passe doit respecter les règles suivantes",
        "password-confirm": "Confirmer le mot de passe",
        "not-email": "Veuillez saisir une adresse mail valide",
        "accept-cgu": "Accepter les termes des ",
        "cgu": "Conditions Générales d'Utilisation",
        "password-l1" : "Votre mot de passe doit être composé d'au moins :",
        "password-l2" : "- 8 caractères",
        "password-l3" : "- une lettre majuscule",
        "password-l4" : "- une lettre minuscule",
        "password-l5" : "- un chiffre",
        "password-l6" : "- un caractère spécial (@ . ! # $ % & ' * + / = ? ^ _ ` { | } ~ -)",
        "This adress already exists": "Cette adresse existe déjà"
    }
}
</i18n>